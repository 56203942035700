import { apiInstance } from "api/instance";
import { z } from "zod";
import { VisaPriceChangelogSchema } from "../../VisaPricesChangelog/schema";

export const getCustomPricingLogs = async (pageNo: number, pageSize: number) =>
	(
		await apiInstance.get<ApiResponse<z.infer<typeof VisaPriceChangelogSchema>[]>>(
			`data-studio/custom-pricing-logs?pageNo=${pageNo}&pageSize=${pageSize}`
		)
	).data.data;

