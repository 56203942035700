import { Route, Routes } from "react-router-dom";
import DefaultPricing from "./Components/DefaultPricing";
import GlobalPricing from "./Components/GlobalPricing";
import OrgSpecificPricing from "./Components/OrgSpecific";
import SegmentSpecific from "./Components/SegmentSpecific";
import PricingContextProvider, { usePricing } from "./Context";

const MainComponent = () => {
	const { activeTab } = usePricing();
	return (
		<>
			{activeTab === "Default" && <DefaultPricing />}
			{activeTab === "Global" && <GlobalPricing />}
			{activeTab === "Segment Specific" && <SegmentSpecific />}
			{activeTab === "Org Specific" && <OrgSpecificPricing />}
		</>
	);
};

const Pricing = () => {
	return (
		<Routes>
			<Route path="" element={<PricingContextProvider />}>
				<Route path="/" element={<MainComponent />} />
			</Route>
		</Routes>
	);
};

export default Pricing;

