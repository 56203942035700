import { CurrencyInr } from "@phosphor-icons/react";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import { Button, Divider, Segmented } from "antd";
import PageHeader from "components/Commons/PageHeader";
import { getCountriesVisaTypes } from "pages/DataStudio/VisaTypes/api";
import { createContext, useContext, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDebounceValue, useLocalStorage } from "x-wings";
import GetPricingModal from "../../../components/GetPricingModal";
import { getOrgsList } from "../api";
import PricesBulkUploadModal from "../Components/PricesBulkUploadModal";

const segmentOptions = ["Default", "Org Specific", "Segment Specific", "Global"] as const;
type SegmentOption = (typeof segmentOptions)[number];

type TPricingContextValues = {
	headerActionRef: React.MutableRefObject<HTMLDivElement | null>;
	activeTab: SegmentOption;
	setActiveTab: React.Dispatch<React.SetStateAction<SegmentOption>>;
	countriesVisaTypesQuery: DefinedUseQueryResult<TCountriesWithVisaType[]>;
	orgSearchQuery: string;
	setOrgSearchQuery: React.Dispatch<React.SetStateAction<string>>;
	orgsQuery: DefinedUseQueryResult<
		{
			label: string;
			value: string;
			status: "APPROVED" | "PENDING" | "REJECTED" | "UNSTARTED";
		}[]
	>;
};

const PricingContext = createContext<TPricingContextValues | undefined>(undefined);

const PricingContextProvider: React.FC = () => {
	const headerActionRef = useRef<HTMLDivElement>(null);
	const [activeTab, setActiveTab] = useLocalStorage<SegmentOption>("pricing-active-tab", "Default");
	const [orgSearchQuery, setOrgSearchQuery] = useState("");
	const [debouncedOrgSearchQuery] = useDebounceValue(orgSearchQuery, 400);

	const countriesVisaTypesQuery = useQuery({
		queryKey: ["countries", "visa-types"],
		queryFn: getCountriesVisaTypes,
		initialData: [],
		select(data) {
			return data.filter((country) => country.is_supported).sort((a, b) => a.name.localeCompare(b.name));
		}
	});

	const orgsQuery = useQuery({
		queryKey: ["getOrgsList", debouncedOrgSearchQuery],
		queryFn: async () => {
			const data = await getOrgsList({
				searchText: debouncedOrgSearchQuery,
				sortBy: "asc",
				counts: false,
				pageNo: 1,
				pageSize: 10
			});
			return data.requests;
		},
		select: (orgs) => {
			return orgs.map((org) => ({
				label: org.name,
				value: org._id,
				status: org.status
			}));
		},
		initialData: [],
		enabled: !!debouncedOrgSearchQuery
	});

	return (
		<PricingContext.Provider
			value={{
				headerActionRef,
				activeTab,
				setActiveTab,
				countriesVisaTypesQuery,
				orgSearchQuery,
				setOrgSearchQuery,
				orgsQuery
			}}>
			<main className="">
				<PageHeader>Pricing</PageHeader>

				<div className="w-full justify-between items-center inline-flex mb-5 mt-5 px-6">
					<div className="justify-start items-center gap-2 flex">
						<CurrencyInr className="w-5 h-5 relative text-gray-500" />
						<div className="text-black text-base font-semibold font-['Inter'] leading-normal flex items-center gap-3">
							Pricing
						</div>
					</div>

					<div ref={headerActionRef} className="ml-auto" />
				</div>
				<section className="h-[calc(100vh_-150px)] max-w-full mx-6">
					<div className="flex justify-between items-center gap-3 py-px">
						<Segmented
							className="select-none [&_.ant-segmented-item-selected_.ant-segmented-item-label]:text-primary-600 transition-colors"
							options={[...segmentOptions]}
							value={activeTab}
							onChange={setActiveTab}
						/>
						<div className="flex items-center gap-3">
							<PricesBulkUploadModal />
							<GetPricingModal>
								{({ open, setOpen }) => (
									<Button onClick={() => setOpen(true)} className="flex items-center gap-1 !h-7">
										Get Pricing for Org <ArrowRight size={14} />
									</Button>
								)}
							</GetPricingModal>
						</div>
					</div>
					<Divider />
					<Outlet />
					<div className="h-6"></div>
				</section>
			</main>
		</PricingContext.Provider>
	);
};

export const usePricing = () => {
	const context = useContext(PricingContext);

	if (!context) {
		throw new Error("usePricing must be within PricingContextProvider");
	}
	return context;
};

export default PricingContextProvider;
