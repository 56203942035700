import { ArrowRight } from "@phosphor-icons/react";
import { useMutation } from "@tanstack/react-query";
import { Button, Divider, Form, InputNumber, Select, Tag } from "antd";
import { createSchemaFieldRule } from "antd-zod";
import TextArea from "antd/es/input/TextArea";
import toast from "components/Commons/Toaster";
import {
	pricingSchema as defaultPricingSchema,
	getPriceByOrgIdAndVisaType,
	updateVisaTypePriceForOrg
} from "pages/Pricing/api";
import { usePricing } from "pages/Pricing/Context";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import colors from "tailwindcss/colors";
import { Flag } from "x-wings";
import { Flags } from "x-wings/Components/Flag/types";
import { z } from "zod";

const pricingSchema = defaultPricingSchema.extend({
	orgId: z.string().min(1, "Organization is required")
});

const rule = createSchemaFieldRule(pricingSchema);

const OrgSpecificPricing = () => {
	const { countriesVisaTypesQuery, orgsQuery, setOrgSearchQuery, orgSearchQuery } = usePricing();
	const flatVisaTypes = useMemo(() => {
		return countriesVisaTypesQuery.data?.flatMap((country) => country.visa_types) || [];
	}, [countriesVisaTypesQuery.data]);

	const [pricing, setPricing] = useState<null | ExtractApiResponseFromPromise<
		ReturnType<typeof getPriceByOrgIdAndVisaType>
	>>(null);
	const [searchParams, setSearchParams] = useSearchParams();

	const [form] = Form.useForm<z.infer<typeof pricingSchema>>();

	const orgSpecificPricingMutation = useMutation({
		mutationKey: ["updateVisaTypePriceForOrg"],
		mutationFn: (data: z.infer<typeof pricingSchema>) => {
			return updateVisaTypePriceForOrg(data);
		},
		onSuccess: () => {
			form.resetFields();
			toast.success("Pricing updated successfully!");
		}
	});

	const onFinish = (values: z.infer<typeof pricingSchema>) => {
		const parsedValues = pricingSchema.safeParse({
			...values,
			visa_fee: values.visa_fee?.amount ? { ...values.visa_fee, currency: "INR" } : undefined,
			vfs_fee: values.vfs_fee?.amount ? { ...values.vfs_fee, currency: "INR" } : undefined,
			service_fee: values.service_fee?.amount ? { ...values.service_fee, currency: "INR" } : undefined,
			child_visa_fee: values.child_visa_fee?.amount ? { ...values.child_visa_fee, currency: "INR" } : undefined
		});
		if (!parsedValues.success) {
			toast.error(parsedValues.error.errors[0].message);
			return;
		}
		orgSpecificPricingMutation.mutate(parsedValues.data);
	};

	const orgId = Form.useWatch(["orgId"], form);
	const visaTypeId = Form.useWatch(["visaTypeId"], form);

	const currentVisaType = flatVisaTypes.find((visaType) => visaType._id === visaTypeId);

	useEffect(() => {
		setSearchParams((prev) => {
			visaTypeId ? prev.set("visa_type_id", visaTypeId) : prev.delete("visa_type_id");
			return prev;
		});
	}, [setSearchParams, visaTypeId]);

	useEffect(() => {
		if (flatVisaTypes.length > 0) {
			const currentVisaType = flatVisaTypes.find((visaType) => visaType._id === searchParams.get("visa_type_id"));
			if (currentVisaType && currentVisaType._id !== form.getFieldValue("visaTypeId")) {
				form.setFieldValue("visaTypeId", currentVisaType._id);
			}
		}
	}, [flatVisaTypes, form, searchParams]);

	useEffect(() => {
		if (!orgId || !visaTypeId) return;
		getPriceByOrgIdAndVisaType(orgId, visaTypeId)
			.then((data) => setPricing(data))
			.catch(() => setPricing(null));
	}, [orgId, visaTypeId]);

	const DefaultVisaTypePriceLabel = ({
		priceType
	}: {
		priceType: "visa_fee" | "vfs_fee" | "service_fee" | "child_visa_fee";
	}) => {
		const defaultPricing = currentVisaType?.pricing[priceType];
		const orgPricing = pricing?.[priceType];

		if (!defaultPricing) return null;

		if (!orgPricing) {
			return (
				<div className="text-gray-600 flex w-full">
					<span className="text-gray-400">Default:&nbsp;</span>
					{defaultPricing?.amount && (
						<span className="font-semibold">
							{Intl.NumberFormat("en-IN", {
								style: "currency",
								currency: defaultPricing.currency
							}).format(defaultPricing.amount)}
						</span>
					)}
				</div>
			);
		}

		return (
			<div className="text-gray-600 flex w-full">
				<span className="text-gray-400">Org/Default:&nbsp;</span>
				{orgPricing?.amount ? (
					<span className="font-semibold">
						{Intl.NumberFormat("en-IN", {
							style: "currency",
							currency: orgPricing.currency
						}).format(orgPricing.amount)}
					</span>
				) : (
					"N/A"
				)}
				&nbsp;/&nbsp;
				{defaultPricing?.amount && (
					<span className="font-semibold">
						{Intl.NumberFormat("en-IN", {
							style: "currency",
							currency: defaultPricing.currency
						}).format(defaultPricing.amount)}
					</span>
				)}
			</div>
		);
	};

	return (
		<Form form={form} onFinish={onFinish} layout="vertical" className="grid grid-cols-[1fr,auto,1fr] gap-5">
			<>
				<section className="flex flex-col gap-4">
					<h2 className="uppercase text-primary-600 font-semibold tracking-wide text-xs">
						Visa Type Section
					</h2>
					<Form.Item label="Visa Type" name="visaTypeId" rules={[rule]}>
						<Select
							placeholder="Search for country or visa type"
							autoFocus
							virtual={false}
							showSearch
							loading={countriesVisaTypesQuery.isFetching}
							optionFilterProp="data-country-visa-type">
							{countriesVisaTypesQuery.data?.map((country) => (
								<Select.OptGroup key={country._id} label={country.name}>
									{country.visa_types.map((visaType) => (
										<Select.Option
											key={`${country._id}-${visaType._id}`}
											value={visaType._id}
											data-country-visa-type={`${country.name} ${country.symbol} ${visaType.visa_type}`}>
											<div className="flex gap-2 items-center">
												<Flag code={country.flag_symbol as Flags} size="s" />
												<span>{visaType.visa_type}</span>
											</div>
										</Select.Option>
									))}
								</Select.OptGroup>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Organization" name="orgId" rules={[rule]}>
						<Select
							showSearch
							placeholder="Search for an organization"
							onSearch={(val) => setOrgSearchQuery(val)}
							value={orgSearchQuery}
							filterOption={false}
							loading={orgsQuery.isLoading}
							virtual={false}
							notFoundContent={
								orgsQuery.isFetched && !orgsQuery.isLoading ? "No organization found" : null
							}>
							{orgsQuery.data.map((org) => (
								<Select.Option key={org.value} value={org.value} data-name={org.label}>
									<div className="grid items-center">
										<p className="truncate">{org.label}</p>

										<div className="absolute right-4">
											{org.status === "APPROVED" && (
												<Tag color={colors.emerald[600]}>Approved</Tag>
											)}
											{org.status === "REJECTED" && <Tag color={colors.rose[600]}>Rejected</Tag>}
											{org.status === "PENDING" && <Tag color={colors.yellow[600]}>Pending</Tag>}
											{org.status === "UNSTARTED" && (
												<Tag color={colors.gray[600]}>Un-started</Tag>
											)}
										</div>
									</div>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</section>
				<Divider type="vertical" className="h-full" />

				<section className="grid grid-cols-2 gap-4">
					<div className="grid grid-cols-[1fr,auto] gap-1 items-start  col-span-2">
						<h2 className="uppercase text-primary-600 font-semibold tracking-wide text-xs">
							Pricing Section
							<p className="text-gray-500 normal-case font-normal">*All price fields are optional.</p>
						</h2>

						<Button
							className="!h-7"
							type="primary"
							htmlType="submit"
							loading={orgSpecificPricingMutation.isPending}>
							Update Price
							<ArrowRight />
						</Button>
					</div>
					<Form.Item
						label="Visa Fee Amount"
						name={["visa_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel priceType="visa_fee" />}>
						<InputNumber className="w-full" min={-1} />
					</Form.Item>
					<Form.Item
						label="VFS Fee Amount"
						name={["vfs_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel priceType="vfs_fee" />}>
						<InputNumber className="w-full" min={-1} />
					</Form.Item>
					<Form.Item
						label="Service Fee Amount"
						name={["service_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel priceType="service_fee" />}>
						<InputNumber className="w-full" min={0} />
					</Form.Item>
					<Form.Item
						label="Child Visa Fee Amount"
						name={["child_visa_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel priceType="child_visa_fee" />}>
						<InputNumber className="w-full" min={-1} />
					</Form.Item>
					<Form.Item label="Comment" name="comment" rules={[rule]} className="col-span-2">
						<TextArea
							autoSize={{
								minRows: 2,
								maxRows: 3
							}}
						/>
					</Form.Item>
				</section>
			</>
		</Form>
	);
};

export default OrgSpecificPricing;
