import NotFoundPage from "components/Commons/NotFoundPage";
import Auth from "pages/Auth";
import Changelog from "pages/Changelog";
import LogisticOrders from "pages/LogisticOrders";
import Pricing from "pages/Pricing";
import Reports from "pages/Reports";
import Segments from "pages/Segments";
import VisaPricesChangelog from "pages/VisaPricesChangelog";

import { lazy } from "react";
import { RouteProps } from "react-router-dom";

const Home = lazy(() => import("pages/Home"));
const Order = lazy(() => import("pages/Order"));
const Orders = lazy(() => import("pages/Orders"));
const Applications = lazy(() => import("pages/Applications"));
const KycRequests = lazy(() => import("pages/KycRequests"));
const DataStudio = lazy(() => import("pages/DataStudio"));
const Verdict = lazy(() => import("pages/Verdict"));
const LogisticTasks = lazy(() => import("pages/LogisticTasks"));

export const PRIVATE_ROUTES: RouteProps[] = [
	{
		path: "/",
		element: <Home />
	},
	{
		path: "/applications/list",
		element: <Applications />
	},
	{
		path: "/orders/list",
		element: <Orders />
	},
	{
		path: "/orders/details/:orderId/*",
		element: <Order />
	},
	{
		path: "/logistic/orders",
		element: <LogisticOrders />
	},
	{
		path: "/logistic/tasks",
		element: <LogisticTasks />
	},
	{
		path: "/kyc-requests",
		element: <KycRequests />
	},
	{
		path: "/segments",
		element: <Segments />
	},
	{
		path: "/data-studio/*",
		element: <DataStudio />
	},
	{
		path: "/reports/*",
		element: <Reports />
	},
	{
		path: "/verdict/quality-check/:applicationId",
		element: <Verdict />
	},
	{
		path: "/pricing",
		element: <Pricing />
	},
	{
		path: "/visa-prices-changelog",
		element: <VisaPricesChangelog />
	},
	{
		path: "*",
		element: <NotFoundPage />
	}
];

export const PUBLIC_ROUTES: RouteProps[] = [
	{
		path: "/auth/*",
		element: <Auth />
	},
	{
		path: "/changelog",
		element: <Changelog />
	},
	{
		path: "*",
		element: <NotFoundPage />
	}
];
