import { Warning } from "@phosphor-icons/react";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Divider, Form, InputNumber, Popconfirm, Select } from "antd";
import { createSchemaFieldRule } from "antd-zod";
import TextArea from "antd/es/input/TextArea";
import toast from "components/Commons/Toaster";
import { pricingSchema, updateVisaTypeGlobalPricing } from "pages/Pricing/api";
import { usePricing } from "pages/Pricing/Context";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Flag } from "x-wings";
import { Flags } from "x-wings/Components/Flag/types";
import { z } from "zod";

const rule = createSchemaFieldRule(pricingSchema);

const GlobalPricing = () => {
	const { countriesVisaTypesQuery, activeTab } = usePricing();
	const [searchParams, setSearchParams] = useSearchParams();
	const client = useQueryClient();

	const flatVisaTypes = useMemo(() => {
		return countriesVisaTypesQuery.data?.flatMap((country) => country.visa_types) || [];
	}, [countriesVisaTypesQuery.data]);

	const [visaType, setVisaType] = useState<
		(typeof countriesVisaTypesQuery)["data"][number]["visa_types"][number] | null
	>(flatVisaTypes.find((visaType) => visaType._id === searchParams.get("visa_type_id")) || null);

	const [form] = Form.useForm<z.infer<typeof pricingSchema>>();

	useEffect(() => {
		if (visaType) {
			setSearchParams((prev) => {
				prev.set("visa_type_id", visaType._id);
				return prev;
			});
		}
	}, [setSearchParams, visaType]);

	useEffect(() => {
		if (flatVisaTypes.length > 0) {
			const currentVisaType = flatVisaTypes.find((visaType) => visaType._id === searchParams.get("visa_type_id"));
			if (currentVisaType && currentVisaType._id !== form.getFieldValue("visaTypeId")) {
				form.setFieldValue("visaTypeId", currentVisaType._id);
				setVisaType(currentVisaType);
			}
		}
	}, [flatVisaTypes, form, searchParams, activeTab]);

	const globalPricingMutation = useMutation({
		mutationKey: ["updateVisaTypeGlobalPricing"],
		mutationFn: (data: z.infer<typeof pricingSchema>) => {
			return updateVisaTypeGlobalPricing(data);
		},
		onSuccess: () => {
			form.resetFields();
			client.invalidateQueries({ queryKey: ["countries", "visa-types"] });
			toast.success("Pricing updated successfully!");
		}
	});

	const onFinish = (values: z.infer<typeof pricingSchema>) => {
		const parsedValues = pricingSchema.safeParse({
			...values,
			visa_fee: values.visa_fee?.amount ? { ...values.visa_fee, currency: "INR" } : undefined,
			vfs_fee: values.vfs_fee?.amount ? { ...values.vfs_fee, currency: "INR" } : undefined,
			service_fee: values.service_fee?.amount ? { ...values.service_fee, currency: "INR" } : undefined,
			child_visa_fee: values.child_visa_fee?.amount ? { ...values.child_visa_fee, currency: "INR" } : undefined
		});
		if (!parsedValues.success) {
			toast.error(parsedValues.error.errors[0].message);
			return;
		}
		globalPricingMutation.mutate(parsedValues.data);
	};

	const DefaultVisaTypePriceLabel = ({
		pricing
	}: {
		pricing?: (typeof countriesVisaTypesQuery)["data"][number]["visa_types"][number]["pricing"][
			| "child_visa_fee"
			| "visa_fee"
			| "vfs_fee"
			| "service_fee"];
	}) =>
		pricing?.amount ? (
			<span className="text-gray-600 ">
				<span className="text-gray-400">Default Price: </span>
				<span className="font-semibold">
					{Intl.NumberFormat("en-IN", {
						style: "currency",
						currency: pricing.currency
					}).format(pricing.amount)}
				</span>
			</span>
		) : null;

	return (
		<Form form={form} onFinish={onFinish} layout="vertical" className="grid grid-cols-[1fr,auto,1fr] gap-5">
			<>
				<div className="bg-amber-50 py-2 px-4 rounded-md border border-amber-200 col-span-3 flex items-center gap-2 w-full">
					<Warning className="text-amber-600" />
					<p className="text-sm text-amber-600">
						Warning: Changes made here will override all other pricing configurations (default, segment, and
						organization specific) and apply globally
					</p>
				</div>
				<section className="flex flex-col gap-4">
					<h2 className="uppercase text-primary-600 font-semibold tracking-wide text-xs">
						Visa Type Section
					</h2>
					<Form.Item label="Visa Type" name="visaTypeId" rules={[rule]}>
						<Select
							placeholder="Search for country or visa type"
							autoFocus
							virtual={false}
							showSearch
							loading={countriesVisaTypesQuery.isFetching}
							optionFilterProp="data-country-visa-type"
							onChange={(value) => {
								const visaType = flatVisaTypes.find((visaType) => visaType._id === value);
								if (visaType) setVisaType(visaType);
								else setVisaType(null);
							}}>
							{countriesVisaTypesQuery.data?.map((country) => (
								<Select.OptGroup key={country._id} label={country.name}>
									{country.visa_types.map((visaType) => (
										<Select.Option
											key={`${country._id}-${visaType._id}`}
											value={visaType._id}
											data-country-visa-type={`${country.name} ${country.symbol} ${visaType.visa_type}`}>
											<div className="flex gap-2 items-center">
												<Flag code={country.flag_symbol as Flags} size="s" />
												<span>{visaType.visa_type}</span>
											</div>
										</Select.Option>
									))}
								</Select.OptGroup>
							))}
						</Select>
					</Form.Item>
				</section>
				<Divider type="vertical" className="h-full" />

				<section className="grid grid-cols-2 gap-4">
					<div className="grid grid-cols-[1fr,auto] gap-1 items-start  col-span-2">
						<h2 className="uppercase text-primary-600 font-semibold tracking-wide text-xs">
							Pricing Section
							<p className="text-gray-500 normal-case font-normal">*All price fields are optional.</p>
						</h2>

						<Popconfirm
							title="Override all pricing configurations globally?"
							onConfirm={form.submit}
							okText="Yes"
							cancelText="No"
							disabled={globalPricingMutation.isPending}>
							<Button className="!h-7" type="primary" loading={globalPricingMutation.isPending}>
								Update Price <ArrowRight />
							</Button>
						</Popconfirm>
					</div>
					<Form.Item
						label="Visa Fee Amount"
						name={["visa_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel pricing={visaType?.pricing.visa_fee} />}>
						<InputNumber className="w-full" min={-1} />
					</Form.Item>
					<Form.Item
						label="VFS Fee Amount"
						name={["vfs_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel pricing={visaType?.pricing.vfs_fee} />}>
						<InputNumber className="w-full" min={-1} />
					</Form.Item>
					<Form.Item
						label="Service Fee Amount"
						name={["service_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel pricing={visaType?.pricing.service_fee} />}>
						<InputNumber className="w-full" min={0} />
					</Form.Item>
					<Form.Item
						label="Child Visa Fee Amount"
						name={["child_visa_fee", "amount"]}
						rules={[rule]}
						help={<DefaultVisaTypePriceLabel pricing={visaType?.pricing.child_visa_fee} />}>
						<InputNumber className="w-full" min={-1} />
					</Form.Item>
					<Form.Item label="Comment" name="comment" rules={[rule]} className="col-span-2">
						<TextArea
							autoSize={{
								minRows: 2,
								maxRows: 3
							}}
						/>
					</Form.Item>
				</section>
			</>
		</Form>
	);
};

export default GlobalPricing;
