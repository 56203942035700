import { ArrowRight, CaretRight, ClockCounterClockwise, Money } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { Button, Select, Table, TableProps, Tag, Tooltip } from "antd";
import PageHeader from "components/Commons/PageHeader";

import dayjs from "dayjs";
import { useState } from "react";
import colors from "tailwindcss/colors";
import { Flag } from "x-wings";
import { z } from "zod";
import GetPricingModal from "../../components/GetPricingModal";
import { getCustomPricingLogs } from "./api";
import { VisaPriceChangelogSchema } from "./schema";

const PAGE_SIZE = 20;
type RecordType = z.infer<typeof VisaPriceChangelogSchema>;

const actionTypeMap: Record<
	RecordType["action"],
	{
		color: string;
		children: string;
		className: string;
	}
> = {
	BULK_UPDATE_PRICING: {
		color: colors.blue[600],
		className: "!font-semibold uppercase",
		children: "Bulk Update Pricing"
	},
	CREATE_ORG_MAPPING: {
		color: colors.emerald[600],
		className: "!font-semibold uppercase",
		children: "Create Org Mapping"
	},
	UPDATE_DEFAULT_PRICING: {
		color: colors.amber[600],
		className: "!font-semibold uppercase",
		children: "Update Default Pricing"
	},
	UPDATE_GLOBAL_PRICING: {
		color: colors.rose[600],
		className: "!font-semibold uppercase",
		children: "Update Global Pricing"
	},
	UPDATE_ORG_MAPPING: {
		color: colors.purple[600],
		className: "!font-semibold uppercase",
		children: "Update Org Mapping"
	},
	UPDATE_ORG_MAPPING_REQUEST: {
		color: colors.orange[600],
		className: "!font-semibold uppercase",
		children: "Org Mapping Request"
	},
	UPDATE_SEGMENT_PRICING: {
		color: colors.indigo[600],
		className: "!font-semibold uppercase",
		children: "Update Segment Pricing"
	}
};

const CurrentPriceContent = ({ record }: { record: RecordType }) => (
	<div className="grid gap-2">
		<span className="font-semibold">Default Visa Type Pricing</span>
		<div className="border-t-gray-200 border-t-[1px] border-b-gray-500 border-b-[1px] border-solid" />
		{record.visa_types?.[0] &&
			Object.entries(record.visa_types[0].pricing).map(([key, value]) => (
				<>
					<span className="text-emerald-500 font-semibold uppercase text-xs">{key.replaceAll("_", " ")}</span>
					<span className="text-sm">
						{Intl.NumberFormat("en-IN", {
							style: "currency",
							currency: value.currency
						}).format(value.amount)}
					</span>
				</>
			))}
	</div>
);

const VisaPricesChangelog = () => {
	const [currentPage] = useState(1);
	const [pageSize, setPageSize] = useState(PAGE_SIZE);

	const visaPricesChangeLogQuery = useQuery({
		queryKey: ["visaPricesChangeLog", currentPage, pageSize],
		queryFn: () => getCustomPricingLogs(currentPage, pageSize),
		initialData: []
	});

	const columns: TableProps<RecordType>["columns"] = [
		{
			title: "Visa Type",
			dataIndex: "visa_types",
			fixed: "left",
			width: 400,
			render: (value) => {
				if (!value || !value.length) return "N/A";
				return (
					<div className="grid grid-cols-[auto,1fr] gap-2">
						<Flag code={value[0].country_symbol} size="s" />
						{value[0].visa_type}
					</div>
				);
			}
		},
		{
			title: "Visa Fee",
			dataIndex: "metadata",
			render: (value: RecordType["metadata"], record: RecordType) => {
				if (!value.pricing?.visa_fee) return <span className="text-gray-400">--</span>;
				if (value.pricing?.visa_fee.amount === -1)
					return <span className="text-gray-400 italic tex-xs">Not defined</span>;

				return (
					<Tooltip title={<CurrentPriceContent record={record} />}>
						<Tag className="flex items-center gap-2 cursor-pointer" icon={<Money />}>
							{Intl.NumberFormat("en-IN", {
								style: "currency",
								currency: value.pricing.visa_fee.currency
							}).format(value.pricing.visa_fee.amount)}
						</Tag>
					</Tooltip>
				);
			}
		},
		{
			title: "VFS Fee",
			dataIndex: "metadata",
			render: (value: RecordType["metadata"], record: RecordType) => {
				if (!value.pricing?.vfs_fee) return <span className="text-gray-400">--</span>;
				if (value.pricing?.vfs_fee.amount === -1)
					return <span className="text-gray-400 italic tex-xs">Not defined</span>;

				return (
					<Tooltip title={<CurrentPriceContent record={record} />}>
						<Tag className="flex items-center gap-2 cursor-pointer" icon={<Money />}>
							{Intl.NumberFormat("en-IN", {
								style: "currency",
								currency: value.pricing.vfs_fee.currency
							}).format(value.pricing.vfs_fee.amount)}
						</Tag>
					</Tooltip>
				);
			}
		},

		{
			title: "Service Fee",
			dataIndex: "metadata",
			render: (value: RecordType["metadata"], record: RecordType) => {
				if (!value.pricing?.service_fee) return <span className="text-gray-400">--</span>;
				if (value.pricing?.service_fee.amount === -1)
					return <span className="text-gray-400 italic tex-xs">Not defined</span>;

				return (
					<Tooltip title={<CurrentPriceContent record={record} />}>
						<Tag className="flex items-center gap-2 cursor-pointer" icon={<Money />}>
							{Intl.NumberFormat("en-IN", {
								style: "currency",
								currency: value.pricing.service_fee.currency
							}).format(value.pricing.service_fee.amount)}
						</Tag>
					</Tooltip>
				);
			}
		},
		{
			title: "Child Visa Fee",
			dataIndex: "metadata",
			render: (value: RecordType["metadata"], record: RecordType) => {
				if (!value.pricing?.child_visa_fee) return <span className="text-gray-400">--</span>;
				if (value.pricing?.child_visa_fee.amount === -1)
					return <span className="text-gray-400 italic tex-xs">Not defined</span>;

				return (
					<Tooltip title={<CurrentPriceContent record={record} />}>
						<Tag className="flex items-center gap-2 cursor-pointer" icon={<Money />}>
							{Intl.NumberFormat("en-IN", {
								style: "currency",
								currency: value.pricing.child_visa_fee.currency
							}).format(value.pricing.child_visa_fee.amount)}
						</Tag>
					</Tooltip>
				);
			}
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (value: RecordType["action"], record: RecordType) =>
				actionTypeMap[value] ? <Tag {...actionTypeMap[value]} /> : <Tag color={colors.gray[500]}>{value}</Tag>
		},
		{
			title: "Performed By",
			dataIndex: "performed_by",
			render: (value) => {
				if (!value || !value.length) return "N/A";
				return (
					<div className="flex items-center gap-2 whitespace-nowrap">
						{value[0].first_name} {value[0].last_name}
					</div>
				);
			}
		},
		{
			title: "Updated at",
			dataIndex: "created_at",
			render: (value) => {
				return (
					<span className="whitespace-nowrap">
						{dayjs(value).format("DD MMM, YY")}&nbsp;
						<span className="text-gray-400">{dayjs(value).format("hh:mm A")}</span>
					</span>
				);
			}
		},
		{
			dataIndex: "metadata",
			render: (value: RecordType["metadata"]) => {
				return (
					<Tooltip
						placement="left"
						title={
							<div className="grid grid-cols-1 gap-1 place-items-start">
								{Object.entries(value).map(([key, value]) => {
									if (typeof value === "object") return null;
									return (
										<>
											<div className="whitespace-nowrap text-xs uppercase text-emerald-500 font-semibold">
												{key.replaceAll("_", " ")}:
											</div>
											<div className="whitespace-pre-wrap text-sm">{value}</div>
										</>
									);
								})}
							</div>
						}>
						<span className="flex rounded-full ring-1 ring-primary-600 bg-primary-100 size-5 justify-center items-center gap-1 whitespace-nowrap text-primary-600 hover:underline hover:rotate-180 transition">
							<CaretRight size={14} />
						</span>
					</Tooltip>
				);
			}
		}
	];

	return (
		<main className="">
			<PageHeader>Visa Prices Changelog</PageHeader>

			<div className="w-full justify-between items-center inline-flex mb-5 mt-5 px-6">
				<div className="justify-start items-center gap-2 flex">
					<ClockCounterClockwise className="w-5 h-5 relative text-gray-500" />
					<div className="text-black text-base font-semibold font-['Inter'] leading-normal flex items-center gap-3">
						Visa Prices Changelog
					</div>
				</div>

				<div className="ml-auto flex gap-3">
					<Select
						options={[10, 20, 30, 50].map((size) => ({ label: `${size} items`, value: size }))}
						value={pageSize}
						onChange={setPageSize}
						className="w-28 !h-7"
					/>
					<GetPricingModal>
						{({ open, setOpen }) => (
							<Button
								type="primary"
								onClick={() => setOpen(true)}
								className="flex items-center gap-1 !h-7">
								Get Pricing for Org <ArrowRight size={14} />
							</Button>
						)}
					</GetPricingModal>
				</div>
			</div>
			<section className="h-[calc(100vh_-150px)] max-w-full overflow-hidden px-6 ">
				<Table
					dataSource={visaPricesChangeLogQuery.data}
					columns={columns}
					scroll={{
						x: true,
						y: "calc(100vh - 150px)"
					}}
					tableLayout="fixed"
					pagination={false}
				/>
			</section>
		</main>
	);
};

export default VisaPricesChangelog;
