import { apiInstance } from "api/instance";

type TVisaType = ExtractChildType<TCountriesWithVisaType["visa_types"]>;

export const getCountriesVisaTypes = async () =>
	(await apiInstance.get<ApiResponse<TCountriesWithVisaType[]>>(`data-studio/country-visa-type-studio`)).data.data;

export const getVisaTypesAssets = async () =>
	(
		await apiInstance.get<
			ApiResponse<
				{
					extension: string;
					filename: string;
					signedUrl: string;
					key: string;
				}[]
			>
		>(`data-studio/visa-type-assets`)
	).data.data;

export const uploadVisaTypesAssets = async (files: File[]) => {
	const formData = new FormData();
	files.forEach((file) => {
		formData.append("files", file);
	});

	return (await apiInstance.post<ApiResponse>(`data-studio/upload-doc-preview`, formData)).data.data;
};
export const addUpdateVisaType = async ({
	visaTypeId,
	payload
}: {
	visaTypeId?: string;
	payload: Partial<TVisaType>;
}) =>
	visaTypeId
		? (await apiInstance.put<ApiResponse<any>>(`data-studio/visa-type-studio/${visaTypeId}`, payload)).data.data
		: (await apiInstance.post<ApiResponse<any>>(`data-studio/visa-type-studio`, payload)).data.data;

export const getLineItems = async () => {
	return (await apiInstance.get<ApiResponse<any>>(`invoice/line-items`)).data.data;
};
