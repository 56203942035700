import { Spinner } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { ConfigProvider, Input, Table, TableProps, Tag, TagProps, Tooltip } from "antd";
import clsx from "clsx";
import Pagination from "components/Commons/Pagination/Pagination";
import dayjs from "dayjs";
import Search from "icons/search";
import { useAuthContext } from "pages/Auth/Context";
import { searchLogisticOrderTasks } from "pages/LogisticOrders/api";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import colors from "tailwindcss/colors";
import { Mixpanel } from "utils/mixpanel";
import { Flag, useDebounceValue } from "x-wings";
import useLogisticTasksReducer, { TASKS_FILTERS } from "./reducer";
import "./styles.scss";

type OrderTasksTableProps = {
	actionContainerRef?: React.RefObject<HTMLDivElement>;
};

export const TASK_TYPE_MAP: {
	[key in TLogisticOrderTask["type"]]: Omit<TagProps, "ref">;
} = {
	COLLECTION: {
		children: "Collection",
		color: colors.emerald[100],
		bordered: true,
		className: "!text-emerald-800"
	},
	DROP: {
		children: "Drop",
		color: colors.blue[100],
		bordered: true,
		className: "!text-blue-800"
	},
	PICKUP: {
		children: "Pickup",
		color: colors.gray[100],
		bordered: true,
		className: "!text-gray-800"
	},
	SUBMISSION: {
		children: "Submission",
		color: colors.amber[100],
		bordered: true,
		className: "!text-amber-800"
	}
};

const OrderTasksTable: React.FC<OrderTasksTableProps> = ({ actionContainerRef }) => {
	const [state, dispatch] = useLogisticTasksReducer();
	const { reportees } = useAuthContext();
	const navigate = useNavigate();

	const [debouncedState] = useDebounceValue(state, 400);

	const {
		data: { data: tasks, count: tasksCount },
		isFetching
	} = useQuery({
		queryKey: ["logisticOrderTasks", debouncedState],
		queryFn: () => searchLogisticOrderTasks(debouncedState),
		initialData: { data: [], count: 0 }
	});

	const handleSearchChange = (value: string) => {
		dispatch({
			type: "SEARCH_CHANGE",
			payload: value || undefined
		});
	};

	const handleNextPage = () => {
		Mixpanel.track(Mixpanel.events.LOGISTIC_ORDER.PAGINATION_CHANGE, {
			page: state.skip / state.limit + 2
		});
		dispatch({
			type: "PAGINATION_CHANGE",
			payload: state.skip + state.limit
		});
	};

	const handlePrevPage = () => {
		Mixpanel.track(Mixpanel.events.LOGISTIC_ORDER.PAGINATION_CHANGE, {
			page: state.skip / state.limit
		});
		dispatch({
			type: "PAGINATION_CHANGE",
			payload: state.skip - state.limit
		});
	};

	const handleOnFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const newFilters = state.filters.includes(value) ? state.filters.filter((f) => f !== value) : [value];
		if (newFilters.length !== 0) {
			Mixpanel.track(Mixpanel.events.LOGISTIC_ORDER.FILTER_CHANGE, {
				filter: newFilters[0]
			});
		}
		dispatch({
			type: "FILTER_CHANGE",
			payload: newFilters
		});
	};

	const handleRowEvents = (record: RecordType) => {
		return {
			onClick: () => {
				Mixpanel.track(Mixpanel.events.LOGISTIC_ORDER.NAVIGATE_TO_TASKS, {
					order_id: record.smv_order_id,
					task_type: record.type,
					applicant_count: record.applicant_count
				});
				navigate(`/logistic/tasks?q=${record.smv_order_id}`);
			}
		};
	};

	const getLogisticPartner = (_id: string) => {
		return reportees.LOGISTICS_PARTNER.find((_) => _._id === _id);
	};

	const getVisaExpert = (_id: string) => {
		return reportees.VISA_EXPERT.find((_) => _._id === _id);
	};

	type RecordType = TLogisticOrderTask;

	const columns: TableProps<RecordType>["columns"] = [
		{
			title: "Order ID",
			fixed: "left",
			render: (record: RecordType) => {
				const countrySymbol = record.smv_order_id.split("-")?.[1];
				return (
					<div className="flex items-center gap-3 max-w-full text-[13px] leading-4 tracking-[-0.04px]">
						<Flag
							code={countrySymbol as any}
							size="s"
							hasBorder
							hasBorderRadius
							hasDropShadow
							gradient="real-linear"
						/>
						<Link
							onClick={(e) => e.stopPropagation()}
							to={`/orders/details/${record.visa_order_id}`}
							className="text-gray-600 hover:text-primary-600 hover:underline font-semibold tabular-nums">
							{record.smv_order_id}
						</Link>
					</div>
				);
			}
		},
		{
			dataIndex: "travel_start_date",
			title: "Travel Start Date",
			render: (travelStartDate: string) => {
				return <span>{dayjs(travelStartDate).format("DD MMM, YYYY")}</span>;
			}
		},
		{
			dataIndex: "travel_end_date",
			title: "Travel End Date",
			render: (travelEndDate: string) => {
				return <span>{dayjs(travelEndDate).format("DD MMM, YYYY")}</span>;
			}
		},
		{
			dataIndex: "applicant_count",
			title: "Applicants",
			className: "text-center"
		},
		{
			dataIndex: "type",
			title: "Type",
			render: (status: TLogisticTask["type"]) => {
				return <Tag {...TASK_TYPE_MAP[status]} />;
			}
		},
		{
			dataIndex: "assigned_for",
			title: "Assigned For",
			render: (assignedFor: string[]) => {
				const sortedAssignedFor = assignedFor.sort((a, b) => dayjs(a).diff(dayjs(b)));
				return assignedFor ? (
					<Tooltip
						open={sortedAssignedFor.length > 2 ? undefined : false}
						title={
							<div className=" grid grid-cols-2 gap-y-2">
								{sortedAssignedFor.map((_) => {
									if (!_) return "--";
									return (
										<Tag
											color="white"
											bordered
											className={clsx("!text-[#334155] !border-[#94A3B8] !border-dashed")}>
											{dayjs(_).format("DD MMM, YYYY")}
										</Tag>
									);
								})}
							</div>
						}>
						<span className="font-medium flex items-center ">
							{sortedAssignedFor.slice(0, 2).map((_) => {
								if (!_) return "--";
								const isOverdue = dayjs(_).isBefore(dayjs(), "day");
								return (
									<Tag
										color="white"
										bordered
										className={clsx(
											"!border-dashed",
											isOverdue
												? "!text-rose-600 !border-rose-500 !bg-rose-50"
												: "!text-[#334155] !border-[#94A3B8]"
										)}>
										{dayjs(_).format("DD MMM, YYYY")}
									</Tag>
								);
							})}
							{sortedAssignedFor.length > 2 && (
								<span className="text-gray-400">+{sortedAssignedFor.length - 2} more</span>
							)}
						</span>
					</Tooltip>
				) : (
					<span className="text-gray-400">--</span>
				);
			}
		},
		{
			dataIndex: "assignees",
			title: "Assignees",
			render: (assignees: string[]) => {
				return (
					<Tooltip
						open={assignees.length > 2 ? undefined : false}
						title={
							<div>
								{assignees.map((assignee) => {
									const user = getLogisticPartner(assignee);
									return <div> {user ? `${user.first_name} ${user.last_name}` : "Unknown"}</div>;
								})}
							</div>
						}>
						<div>
							{assignees
								.slice(0, 2)
								.map((assignee) => {
									const user = getLogisticPartner(assignee);
									return user ? `${user.first_name} ${user.last_name}` : "Unknown";
								})
								.join(", ")}
							{assignees.length > 2 && (
								<span className="text-gray-400">+{assignees.length - 2} more</span>
							)}
						</div>
					</Tooltip>
				);
			}
		},
		{
			dataIndex: "visa_experts",
			title: "Visa Experts",
			render: (experts: string[]) => {
				return (
					<Tooltip
						open={experts.length > 2 ? undefined : false}
						title={
							<div>
								{experts.map((assignee) => {
									const user = getVisaExpert(assignee);
									return <div> {user ? `${user.first_name} ${user.last_name}` : "Unknown"}</div>;
								})}
							</div>
						}>
						<div>
							{experts
								.slice(0, 2)
								.map((assignee) => {
									const user = getVisaExpert(assignee);
									return user ? `${user.first_name} ${user.last_name}` : "Unknown";
								})
								.join(", ")}
							{experts.length > 2 && <span className="text-gray-400">+{experts.length - 2} more</span>}
						</div>
					</Tooltip>
				);
			}
		}
	];

	useEffect(() => {
		Mixpanel.track(Mixpanel.events.LOGISTIC_ORDER.SEARCH_CHANGE, {
			query: debouncedState.searchText
		});
	}, [debouncedState.searchText]);

	return (
		<>
			<div role="radiogroup" className="justify-start items-start flex gap-x-1.5 gap-y-1 flex-wrap mb-6">
				{TASKS_FILTERS.map(({ label, key }, index) => {
					const isSelected = state.filters.includes(key);
					return (
						<label
							className={clsx(
								"my-px cursor-pointer flex items-center transition-colors border border-gray-400 rounded-full px-2.5 h-[22px]",
								isSelected ? "bg-primary-50 border-primary-400 border-solid" : "border-dashed"
							)}>
							<input
								type="checkbox"
								name="status"
								value={key}
								className="hidden"
								checked={isSelected}
								onChange={handleOnFilterChange}
							/>
							<span
								className={clsx(
									"text-gray-600 text-xs font-medium font-['Inter'] leading-tight",
									isSelected && "text-primary-600"
								)}>
								{label}
							</span>
						</label>
					);
				})}
			</div>

			{actionContainerRef?.current &&
				createPortal(
					<>
						<Input
							className="w-[250px] shadow-1 !h-7 !px-2.5 bg-white rounded-md"
							placeholder="Search order..."
							defaultValue={state.searchText}
							onChange={(e) => {
								handleSearchChange(e.target.value);
							}}
							allowClear
							prefix={<Search stroke={colors.gray[400]} width={16} height={16} />}
						/>
					</>,
					actionContainerRef.current
				)}
			<ConfigProvider
				renderEmpty={() => (
					<div
						style={{ minHeight: state.limit * 40 - 32 }}
						className={clsx("flex flex-col justify-center items-center", isFetching && "animate-pulse")}>
						{!isFetching && (
							<>
								<span className="text-gray-500 font-medium mb-1 text-[13px] leading-4">
									No Task found!
								</span>
								<p className="text-center text-gray-400 text-xs leading-tight">
									(Please check the filters applied)
								</p>
							</>
						)}
					</div>
				)}>
				<Table
					onRow={handleRowEvents}
					columns={columns}
					tableLayout="fixed"
					rowKey="_id"
					dataSource={tasks}
					loading={{
						spinning: isFetching,
						indicator: (
							<div
								style={{ minHeight: state.limit * 40 }}
								className={clsx("flex flex-col justify-center items-center")}>
								<Spinner className="animate-spin text-primary-600" size={28} />
							</div>
						)
					}}
					scroll={{
						x: true
					}}
					className={"logistic-tasks-listing"}
					pagination={false}
				/>
			</ConfigProvider>
			<Pagination
				label="Tasks"
				total={tasksCount}
				currentPage={state.skip / state.limit + 1}
				handleNextPage={handleNextPage}
				handlePrevPage={handlePrevPage}
				perPage={state.limit}
			/>
		</>
	);
};

export default OrderTasksTable;
