import { UploadSimple } from "@phosphor-icons/react";
import { Button, Dropdown, Form, Upload, UploadProps } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import toast from "components/Commons/Toaster";
import { bulkUpdateVisaTypePricing } from "pages/Pricing/api";
import React, { useEffect, useState } from "react";
import { Modal } from "x-wings";

type BulkVisaTypePriceUpdate = {};

type TFormValues = {
	comment: string;
	file: {
		file: File;
	};
};

const PricesBulkUploadModal: React.FC<BulkVisaTypePriceUpdate> = () => {
	const [open, setOpen] = useState(false);
	const [form] = useForm<TFormValues>();
	const [loading, setLoading] = useState(false);

	const uploadProps: UploadProps = {
		className:
			"bg-transparent [&_.ant-upload-list]:max-h-24 [&_.ant-upload-list]:overflow-y-auto [&_.ant-upload-list]:scrollbar-hidden",
		name: "files",
		maxCount: 1,
		accept: ".xlsx",
		beforeUpload: () => false
	};

	const onClose = () => setOpen(false);

	const onFormFinish = async (values: TFormValues) => {
		try {
			setLoading(true);
			await bulkUpdateVisaTypePricing({
				comment: values.comment,
				file: values.file.file
			});
			toast.success("Visa prices will be updated shortly.");
			setOpen(false);
		} finally {
			setLoading(false);
		}
	};

	const downloadTemplate = () => {
		const url =
			"https://stampmyvisa-public.s3.ap-south-1.amazonaws.com/important-docs/Bulk+Update+Visa+Fee+Template.xlsx";
		window.open(url, "_blank");
	};

	useEffect(() => {
		if (open) {
			form.resetFields();
		}
	}, [open, form]);

	return (
		<>
			<Dropdown.Button
				className="[&_button]:!h-7"
				menu={{
					items: [
						{
							key: "download-template",
							label: "Download Template",
							onClick: downloadTemplate
						}
					]
				}}
				onClick={() => setOpen(true)}>
				Bulk Price Update
			</Dropdown.Button>

			<Modal
				title="Bulk Update Visa Prices"
				open={open}
				onCancel={onClose}
				footer={
					<div className="grid grid-cols-2 gap-3">
						<Button onClick={onClose}>Cancel</Button>
						<Button type="primary" onClick={() => form.submit()} loading={loading}>
							Upload
						</Button>
					</div>
				}
				width={440}>
				<Form<TFormValues> form={form} onFinish={onFormFinish} layout="vertical" className="grid gap-5">
					<Form.Item
						name="comment"
						label="Comment"
						rules={[{ required: true, message: "Please add comment" }]}>
						<TextArea autoFocus placeholder="Add comment" autoSize={{ minRows: 2, maxRows: 3 }} />
					</Form.Item>
					<Form.Item
						name="file"
						label="Upload XLSX File"
						rules={[{ required: true, message: "Please upload a file." }]}>
						<Upload.Dragger {...uploadProps}>
							<div
								className="flex justify-center place-items-center cursor-pointer text-gray-600 text-sm"
								role="button"
								tabIndex={0}>
								<UploadSimple size={20} className="mx-1" />
								<span>Upload tasks sheet (xlsx)</span>
							</div>
						</Upload.Dragger>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default PricesBulkUploadModal;
